




























































































































































































































































































































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {
    BDropdown,
    BIcon,
    BIconPlusCircle,
    BIconXCircleFill,
    BIconArrowLeftSquare,
    BIconArrowRightSquare,
    BIconCheckCircle,
    BIconServer,
    BTable,
    BIconExclamationCircleFill
} from 'bootstrap-vue'
import CClarifyAddModal from "@/modules/budget-clarify/components/ClarifyAddModal.vue";
import {genListOfYears, listForClarify, variantName} from "@/modules/budget/budgetCorrectHelper";
import VueElementLoading from 'vue-element-loading';
import TreeEbkDiv from '@/modules/budget/budget-correct/components/tree-ebk-div.vue'
import {
    budget_level_of_region_code,
    BudgetCorrectRowNew,
    BudgetCostData, ClarifyData,
    CorrectData,
    CorrectRowClass,
    CorrectSaveData,
    EbkCodeRequest,
    FilterEventOwner, noAbc
} from './CorectHelper'
import {IVariant, loadVariantByUiid} from "@/modules/budget/budgetVariantService";
import {debug} from "@amcharts/amcharts4/.internal/core/utils/Debug";

@Component({
    methods: {budget_level_of_region_code, noAbc},
    components: {
        'c-add-modal': CClarifyAddModal,
        'b-icon': BIcon,
        'b-icon-check-circle': BIconCheckCircle,
        'left-icon': BIconArrowLeftSquare,
        'right-icon': BIconArrowRightSquare,
        'save-icon': BIconServer,
        'loading': VueElementLoading,
        'tree-ebk-div': TreeEbkDiv,
        'b-icon-x-circle-fill': BIconXCircleFill,
        'b-icon-plus-circle': BIconPlusCircle,
        BIconExclamationCircleFill
    }
})

export default class CostClarifySpf extends Vue {
    private correct_data: ClarifyData = new ClarifyData();
    private addShow: boolean = false;
    private region: string = '';
    private curYear: number = new Date().getFullYear();
    private variant: string = '';
    private editable = false;
    private loading = false;
    private module_code = "004.005.001";
    private access_level = 1;

    @Prop({
        required: true,
        default: new Date().getFullYear()
    })
    year!: number;
    @Prop({
        required: true,
        default: 55
    })
    obl!: String;
    @Prop({
        required: true,
        default: ''
    })
    reg!: String;
    @Prop({
        required: true,
        default: []
    })
    listReg: any

    @Prop({
        required: true,
        default: []
    })
    listVariants: any;


    get year_list() {
        const res = [];
        for (let i = 0; i < 3; i++) {
            res.push(this.curYear + i);
        }
        return res;
    }

    private hide() {
        (this.$refs.drop as BDropdown).hide(true)
    }

    private do_correct_row_all() {
        for (const row of this.correct_data.rows) {
            row.do_correct_row();
        }
    }

    //
    // get budget_level(): number {
    //     const end_code = this.region.substr(3, 3);
    //     if (end_code === '101') {
    //         return 2
    //     } else {
    //         return 3
    //     }
    // }

    private save_correct_row_all() {
        for (const row of this.correct_data.rows) {
            this.save_correct_row(row, true);
        }
        this.makeToast("success", "Результат сохранения", "Сохранено");
    }

    public row_hover(item: CorrectRowClass) {
        this.correct_data.ebk_duplicate_rows = [];
        if (item.ebk_duplicates.length > 0) {
            this.correct_data.ebk_duplicate_rows = this.correct_data.ebk_duplicate_rows.concat(item.ebk_duplicates);
            this.correct_data.ebk_duplicate_rows.push(item);
        }
    }

    public row_class(item: CorrectRowClass, type: any) {
        if (!item || type !== 'row') return;
        if (!item.visible) {
            return "hidden"
        }
        if (this.correct_data.ebk_duplicate_rows.indexOf(item) > -1) {
            return "table-success";
        }
    }

    private do_correct_row(row: CorrectRowClass) {
        const res = row.do_correct_row();
        if (res.length > 0) {
            this.makeToast("danger", "Сообщение", res);
        }
    }


    private edited_row: CorrectRowClass | null = null;

    public edit_row_node(row: CorrectRowClass) {
        this.edited_row = row;
        this.$root.$emit('bv::show::modal', 'modal-cost-note')
    }

    // public get_object_native_id(object: any) {
    //     return object.__ob__.dep.id;
    // }
    private async delete_cost_data(item: CorrectRowClass) {
        try {
            const response = await fetch('/api-py/delete-cost-data-dubl/' + item.cost_data_id);
            let result: any;
            if (response.status === 200) {
                result = await response.json();
                this.makeToast("success", "Результат сохранения", result.message);
                if (result.status == true) {
                    this.correct_data.delete_row(item);
                    this.correct_data.find_row_ebk_duplicates();
                }
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса load_correct_fields', error.toString());
            return;
        }
    }

    private async delete_correct_row(item: CorrectRowClass) {
        if (!item.is_correct_zero_and_may_deleted) {
            this.makeToast("danger", "Ошибка", "Попытка удалить не пустую строку!");
            return
        }
        const field_values = item.get_field_values();
        const saveData: CorrectSaveData = {
            variant: this.variant,
            abp: item.abp,
            prg: item.prg,
            ppr: item.ppr,
            spf: item.spf,
            bip_code: item.bip_code,
            note: item.note,
            variant_cost_data: item.variant_cost_data,
            sum_correct: item.sum_correct,
            field_values: field_values,
            user_name: this.$store.getters.user_uuid,
            cur_year: this.curYear,
            year: item.year,
            is_correct_changed: item.is_correct_changed,
            is_cost_changed: item.is_updated,
        }
        let result: any
        try {
            result = await fetch('/api-py/delete-correct-data', {
                method: 'POST',
                body: JSON.stringify(saveData)
            })
            if (result.status === 200) {
                result = await result.json();
                this.makeToast("success", "Результат сохранения", result.message);
                if (result.status == true) {
                    this.correct_data.delete_row(item);
                }
            } else {
                this.makeToast('danger', 'save-correct-data', `${result.status} - ${result.statusText}`);
                return;
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса save-correct-data', (error as Error).toString());
            return;
        }
    }

    private async save_correct_row(item: CorrectRowClass, is_all?: boolean) {
        const field_values = item.get_field_values();
        const saveData: CorrectSaveData = {
            variant: this.variant,
            abp: item.abp,
            prg: item.prg,
            ppr: item.ppr,
            spf: item.spf,
            bip_code: item.bip_code_as_null,
            note: item.note,
            variant_cost_data: item.variant_cost_data == null ? 0 : item.variant_cost_data,
            sum_correct: item.sum_correct,
            field_values: field_values,
            user_name: this.$store.getters.user_uuid,
            cur_year: this.curYear,
            year: item.year,
            is_correct_changed: item.is_correct_changed,
            is_cost_changed: item.is_updated,
        }
        let result: any
        try {
            result = await fetch('/api-py/save-correct-data', {
                method: 'POST',
                body: JSON.stringify(saveData)
            })
            if (result.status === 200) {
                result = await result.json();
                if (!is_all) {
                    this.makeToast("success", "Результат сохранения", result.message);
                }
                if (result.status == true) {
                    item.set_old_values_from_new_values();
                }
                const temp_rows: CorrectRowClass[] = [];
                await this.load_variant_cost_data(item, temp_rows);
            } else {
                this.makeToast('danger', 'save-correct-data', `${result.status} - ${result.statusText}`);
                return;
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса save-correct-data', (error as Error).toString());
            return;
        }
    }

    get delta_sum(): number {
        let res = 0;
        for (const r of this.correct_data.rows.filter(value => value.year == this.correct_data.year)) {
            if (!isNaN(r.delta)) {
                res = res + r.delta;
            }
        }
        return res;
    }

    get correct_sum(): number {
        let res = 0;
        for (const r of this.correct_data.rows.filter(value => value.year == this.correct_data.year)) {
            if (!isNaN(r.sum_correct)) {
                res = res + r.sum_correct;
            }
        }
        return res;
    }

    private keyup13(event: any) {
        event.preventDefault();
        // Isolate the node that we're after
        const currentNode = event.target;
        // find all tab-able elements
        const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
        // Find the current tab index.
        const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
        // focus the following element
        const targetIndex = (currentIndex + 1) % allElements.length;
        if (targetIndex < allElements.length) {
            allElements[targetIndex].select();
        }
    }// enter работает как tab

    // noAbc(evt: any) {
    //     // const regex = new RegExp('^-?[0-9]+$');\.?
    //     // const regex = new RegExp('^-?\\d*\\d{0,9}$');
    //     const regex = new RegExp('^-?\\d*\\.?$');
    //     // const key = String.fromCharCode(!evt.key ? evt.which : evt.key);
    //     const key = evt.key
    //     console.debug("key", key);
    //     if (!regex.test(key)) {
    //         evt.preventDefault();
    //         return false;
    //     }
    // }// вводит только цифры

    private user_region() {
        if (this.listReg.length > 0) {
            this.region = this.listReg[0].code;
            this.changeFilter(FilterEventOwner.region);
        } else {
            this.region = '';
        }
    }

    private get listOfYears() {
        return genListOfYears();
    }

    getVariantName(variant: any) {
        if (variant) {
            return variantName(variant);
        } else {
            return "Не выбран"
        }
    }

    private get listOfVariants() {
        return listForClarify(this.listVariants, this.curYear);
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    async mounted() {
        await this.load_correct_fields();
        this.variants_loaded();
        // if (!this.loading) await this.changeFilter(FilterEventOwner.year);
    }

    async created() {
        // this.$watch('listReg', this.user_region);
        if (window.location.host === "localhost:10000") {
            this.region = '450101';
            this.variant = 'b5bbb151-d502-4ff7-b87d-95f44c6e8d26';
            // this.variant = '6bafc39b-428c-4f97-8b33-e883cf47756e';
        }
        // this.$watch('listVariants', this.variants_loaded)
        this.load_user_access_level();
    }

    private async variants_loaded() {
        const queryString = new URL(document.location.href.replace("#", "")).search;
        const params = new URLSearchParams(queryString);
        const version = params.get("version");
        if (version) {
            const variant = await loadVariantByUiid(version);
            if (variant) {
                this.curYear = variant.year;
                if (variant.region_code) {
                    this.region = variant.region_code;
                    this.variant = variant.variant_uuid;
                    await this.changeFilter(FilterEventOwner.variant);
                }
                this.editable = variant.attribute && this.access_level > 1;
                console.debug("variants_loaded");
                if (!this.loading) await this.load_cost_correct();
            }
        }
    }

    @Watch("listVariants", {deep: true})
    async change_list_variants(oldvalue: any, newvalue: any) {
        this.variant = '';
        await this.find_actual_variant();
    }


    @Watch('region')
    @Watch('curYear')
    async change_region(oldvalue: any, newvalue: any) {
        this.correct_data.clear_rows();
        this.correct_data.year = this.curYear;
        this.variant = '';
        const filter = {
            variant: this.variant,
            year: this.curYear,
            region: this.region
        };
        await this.$emit('change_filter', filter);
        await this.find_actual_variant();
    }

    private async changeFilter(event_owner: FilterEventOwner) {
        if (event_owner === FilterEventOwner.year || event_owner === FilterEventOwner.region) {
            this.variant = ""
        }
        const filter = {
            variant: this.variant,
            year: this.curYear,
            region: this.region
        };
        this.correct_data.clear_rows();
        this.correct_data.year = this.curYear;
        // await this.$emit('change_filter', filter);
        await this.find_actual_variant();
    }

    private async find_actual_variant() {
        if (this.variant != '') {
            const select_variant = this.getVariant(this.variant);
            if (select_variant) {
                this.editable = select_variant.attribute && this.access_level > 1;
            }
            if (!this.loading) await this.load_cost_correct();
        } else {
            for (const variant of this.listOfVariants.filter(value => (value.year == this.curYear && value.region_code == this.region))) {
                if (variant.attribute) {
                    this.variant = variant.variant_uuid;
                    const select_variant = this.getVariant(this.variant);
                    if (select_variant) {
                        this.editable = select_variant.attribute && this.access_level > 1;
                    }
                    if (!this.loading) await this.load_cost_correct();
                }

            }
        }
    }

    private async load_cost_correct() {
        this.correct_data.clear_rows();
        this.loading = true;
        let loaded_corrected_data: BudgetCorrectRowNew[] = []
        try {
            const response = await fetch('/api-py/get-cost-correct-by-version-new/' + this.variant)
            if (response.status === 200) {
                const result = await response.json();
                if (result.status == true) {
                    loaded_corrected_data = result.data;
                    for (const row of loaded_corrected_data) {
                        this.correct_data.add_correct_data(row)
                    }
                    await this.correct_data.sort_data_by_code();
                } else {
                    this.makeToast("danger", "Результат сохранения", result.message);
                }
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса loadVariant', error.toString());
            return;
        } finally {
            this.loading = false;
        }
    }

    public async load_correct_fields() {
        const flds = [{
            "code": "20",
            "name_ru": "Оптимизация",
            "name_kk": "Оптимизация kk",
            "is_income": true,
            "beg_date": null,
            "end_date": null
        }, {
            "code": "21",
            "name_ru": "Экономия",
            "name_kk": "Экономия kk",
            "is_income": false,
            "beg_date": null,
            "end_date": null
        }, {
            "code": "22",
            "name_ru": "Доппотребность",
            "name_kk": "Доппотребность kk",
            "is_income": true,
            "beg_date": null,
            "end_date": null
        }, {
            "code": "23",
            "name_ru": "Перераспределение (минусы)",
            "name_kk": "Перераспределение (минусы) kk",
            "is_income": true,
            "beg_date": null,
            "end_date": null
        }, {
            "code": "24",
            "name_ru": "Перераспределение (плюсы)",
            "name_kk": "Перераспределение (плюсы) kk",
            "is_income": false,
            "beg_date": null,
            "end_date": null
        }
        ]
        try {
            // const response = await 5etch('/api-py/dictionary/budget_adjust/');
            this.correct_data.load_fields = flds;
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса load_correct_fields', error.toString());
            return;
        }
    }

    public async load_user_access_level() {
        try {
            const response = await fetch(`/api-py/user-modules/${this.$store.getters.user_uuid}/${this.module_code}`);

            const modules_access = await response.json();
            if (modules_access.length > 1) {
                this.makeToast("danger", "Ошибка", "У пользователя несколько записей")
            }
            for (const ma of modules_access) {
                this.access_level = ma.access_level
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса load_user_access_level', error.toString());
            return;
        }
    }

    private async addClk(obj: any) {
        const added_row = this.correct_data.add_ebk_code(obj);
        const temp_rows: CorrectRowClass[] = [];
        if (added_row) {
            const added_count = await this.load_variant_cost_data(added_row, temp_rows);
            if (added_count === 0) this.correct_data.rows_push(added_row);
            for (const tempRow of temp_rows) {
                this.correct_data.rows_push(tempRow);
            }
            this.load_ebk_names(added_row);
        }
        await this.correct_data.sort_data_by_code()
    }

    private async load_variant_cost_data(row: CorrectRowClass, temp_rows: CorrectRowClass[]) {
        let loaded_cost_data: BudgetCostData[] = []
        const abp = row.abp;
        const prg = row.prg;
        const spf = row.spf;
        const ppr = row.ppr;
        let pre_url = "/api-py/get-cost-data-for-correct-by-variant";
        if (row.bip_code !== "" && row.bip_code !== null) {
            pre_url = pre_url + "-bip"
        }
        let url = `${pre_url}/${this.variant}/${row.year}/${abp}/${prg}/${ppr}/${spf}`;
        if (!ppr) {
            url = `${pre_url}/${this.variant}/${row.year}/${abp}/${prg}/${spf}`;
        }
        if (row.bip_code !== "" && row.bip_code !== null) {
            url = url + "/" + row.bip_code
        }
        try {
            const response = await fetch(url);
            loaded_cost_data = await response.json();
            loaded_cost_data = loaded_cost_data.filter((value: any) => value.year === row.year);
            if (loaded_cost_data.length > 0) {
                let once_added = false;
                for (const dat of loaded_cost_data) {
                    const find_row = this.correct_data.find_by_code(dat.abp, dat.prg, dat.bip_code, dat.spf, row.year, dat.ppr)
                    if (find_row && !once_added) {
                        this.$set(find_row, 'variant_cost_data', dat.budget);
                        this.$set(find_row, 'old_variant_cost_data', dat.budget);
                        this.$set(find_row, 'prev_variant_cost_data', dat.approved_budget);
                        this.$set(find_row, 'abp_request', dat.abp_request);
                        once_added = true;
                    } else {
                        const new_row = new CorrectRowClass();
                        new_row.abp = row.abp;
                        new_row.prg = row.prg;
                        new_row.ppr = row.ppr;
                        new_row.spf = row.spf;
                        new_row.year = row.year;
                        new_row.bip_code = dat.bip_code;
                        new_row.variant_cost_data = dat.budget;
                        new_row.old_variant_cost_data = dat.budget;
                        new_row.abp_request = dat.abp_request;
                        new_row.prev_variant_cost_data = dat.approved_budget;
                        new_row.cost_data_id = dat.cost_data_id;
                        if (find_row) {
                            new_row.field_values = find_row.field_values;
                            new_row.old_field_values = find_row.field_values;
                        }
                        temp_rows.push(new_row);
                        // this.correct_data.rows_push(new_row);
                    }
                }
                return loaded_cost_data.length
            } else {
                const find_row = this.correct_data.find_by_code(row.abp, row.prg, row.bip_code, row.spf, row.year, row.ppr)
                if (find_row)
                    return 1
                else
                    return 0
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса load_variant_cost_data', error.toString());
            return -1;
        }
    }

    load_ebk_names(item: CorrectRowClass) {
        const req_data: EbkCodeRequest = {
            abp: item.abp,
            prg: item.prg,
            ppr: item.ppr,
            spf: item.spf,
            bip_code: item.bip_code
        }
        fetch('/api-py/get-ebk-code', {
            method: 'POST',
            body: JSON.stringify(req_data)
        }).then((response) => response.json()).then((data) => {
            this.$set(item, "names", data.ebk_code);
        })
    }


    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }

    getRegion(regCode: string) {
        const res = this.listReg.find((value: any) => value.code == regCode);
        if (res) {
            return res;
        } else {
            return {
                name_ru: "Не выбран"
            };
        }
    }

    private getVariantUidName(variant_uid: string) {
        const vr = this.listOfVariants.find(value => value.variant_uuid == variant_uid);
        return this.getVariantName(vr)
    }

    private getVariant(variant_uid: string) {
        return this.listOfVariants.find(value => value.variant_uuid == variant_uid);
    }

}
